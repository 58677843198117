var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "c-card",
        { staticClass: "cardClassDetailForm", attrs: { title: "LBL0001922" } },
        [
          _c(
            "template",
            { slot: "card-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: {
                          isSubmit: _vm.isSave,
                          url: _vm.saveUrl,
                          param: _vm.moc,
                          mappingType: "PUT",
                          label: "LBLSAVE",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveMoc,
                          btnCallback: _vm.saveMocCallback,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    disabled: true,
                    editable: _vm.editable,
                    codeGroupCd: "MOC_TYPE_CD",
                    type: "edit",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "mocTypeCd",
                    label: "LBL0001906",
                  },
                  model: {
                    value: _vm.moc.mocTypeCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.moc, "mocTypeCd", $$v)
                    },
                    expression: "moc.mocTypeCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: true,
                    label: "LBLMOCNO",
                    name: "mocNo",
                  },
                  model: {
                    value: _vm.moc.mocNo,
                    callback: function ($$v) {
                      _vm.$set(_vm.moc, "mocNo", $$v)
                    },
                    expression: "moc.mocNo",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    required: true,
                    disabled: _vm.disabled,
                    editable: _vm.editable,
                    codeGroupCd: "MOC_LEVEL_CD",
                    type: "edit",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "mocWriteLevelCd",
                    label: "LBL0001929",
                  },
                  model: {
                    value: _vm.moc.mocWriteLevelCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.moc, "mocWriteLevelCd", $$v)
                    },
                    expression: "moc.mocWriteLevelCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    codeGroupCd: "MOC_LEVEL_CD",
                    type: "edit",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "mocConfirmLevelCd",
                    label: "LBL0001930",
                  },
                  model: {
                    value: _vm.moc.mocConfirmLevelCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.moc, "mocConfirmLevelCd", $$v)
                    },
                    expression: "moc.mocConfirmLevelCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-text", {
                  attrs: {
                    required: true,
                    disabled: _vm.disabled,
                    editable: _vm.editable,
                    label: "LBLTITLE",
                    name: "mocTitle",
                  },
                  model: {
                    value: _vm.moc.mocTitle,
                    callback: function ($$v) {
                      _vm.$set(_vm.moc, "mocTitle", $$v)
                    },
                    expression: "moc.mocTitle",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-plant", {
                  attrs: {
                    required: true,
                    disabled: _vm.disabled,
                    editable: _vm.editable,
                    type: "edit",
                    name: "plantCd",
                  },
                  model: {
                    value: _vm.moc.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.moc, "plantCd", $$v)
                    },
                    expression: "moc.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: true,
                    label: "LBL0001923",
                    name: "initiative",
                  },
                  model: {
                    value: _vm.initiative,
                    callback: function ($$v) {
                      _vm.initiative = $$v
                    },
                    expression: "initiative",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-datepicker", {
                  attrs: {
                    required: true,
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    range: true,
                    label: "LBL0001919",
                    name: "mocPeriod",
                  },
                  model: {
                    value: _vm.moc.mocPeriod,
                    callback: function ($$v) {
                      _vm.$set(_vm.moc, "mocPeriod", $$v)
                    },
                    expression: "moc.mocPeriod",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-field", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    data: _vm.moc,
                    deptValue: "mocActionDeptCd",
                    type: "dept_user",
                    label: "LBL0001931",
                    name: "mocActionResponsibilityUserId",
                  },
                  model: {
                    value: _vm.moc.mocActionResponsibilityUserId,
                    callback: function ($$v) {
                      _vm.$set(_vm.moc, "mocActionResponsibilityUserId", $$v)
                    },
                    expression: "moc.mocActionResponsibilityUserId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-field", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    data: _vm.moc,
                    deptValue: "mocCompleteCheckDeptCd",
                    type: "dept_user",
                    label: "LBL0001932",
                    name: "mocCompleteCheckUserId",
                  },
                  model: {
                    value: _vm.moc.mocCompleteCheckUserId,
                    callback: function ($$v) {
                      _vm.$set(_vm.moc, "mocCompleteCheckUserId", $$v)
                    },
                    expression: "moc.mocCompleteCheckUserId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: true,
                    label: "LBL0001905",
                    name: "initiativeDt",
                  },
                  model: {
                    value: _vm.moc.initiativeDt,
                    callback: function ($$v) {
                      _vm.$set(_vm.moc, "initiativeDt", $$v)
                    },
                    expression: "moc.initiativeDt",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-process", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    label: "LBL0001705",
                    multiple: "multiple",
                    required: true,
                    name: "processCd",
                  },
                  model: {
                    value: _vm.moc.processCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.moc, "processCd", $$v)
                    },
                    expression: "moc.processCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-tag", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    clickable: true,
                    itemText: "equipmentName",
                    itemValue: "check",
                    label: "LBL0001076",
                    name: "equipments",
                  },
                  on: {
                    removeTag: _vm.removeEquipment,
                    addTag: _vm.addEquipment,
                    clickTag: _vm.clickTag,
                  },
                  model: {
                    value: _vm.moc.equipments,
                    callback: function ($$v) {
                      _vm.$set(_vm.moc, "equipments", $$v)
                    },
                    expression: "moc.equipments",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-textarea", {
                  attrs: {
                    disabled: _vm.disabled,
                    editable: _vm.editable,
                    label: "LBL0001933",
                    name: "technicalBasisProcessDesign",
                  },
                  model: {
                    value: _vm.moc.technicalBasisProcessDesign,
                    callback: function ($$v) {
                      _vm.$set(_vm.moc, "technicalBasisProcessDesign", $$v)
                    },
                    expression: "moc.technicalBasisProcessDesign",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-textarea", {
                  attrs: {
                    disabled: _vm.disabled,
                    editable: _vm.editable,
                    label: "LBL0001934",
                    name: "mocSummary",
                  },
                  model: {
                    value: _vm.moc.mocSummary,
                    callback: function ($$v) {
                      _vm.$set(_vm.moc, "mocSummary", $$v)
                    },
                    expression: "moc.mocSummary",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-textarea", {
                  attrs: {
                    disabled: _vm.disabled,
                    editable: _vm.editable,
                    label: "LBL0001935",
                    name: "processSatefySecureMeasures",
                  },
                  model: {
                    value: _vm.moc.processSatefySecureMeasures,
                    callback: function ($$v) {
                      _vm.$set(_vm.moc, "processSatefySecureMeasures", $$v)
                    },
                    expression: "moc.processSatefySecureMeasures",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-textarea", {
                  attrs: {
                    disabled: _vm.disabled,
                    editable: _vm.editable,
                    label: "LBL0001936",
                    name: "safetyReliabilityImprovementEffect",
                  },
                  model: {
                    value: _vm.moc.safetyReliabilityImprovementEffect,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.moc,
                        "safetyReliabilityImprovementEffect",
                        $$v
                      )
                    },
                    expression: "moc.safetyReliabilityImprovementEffect",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-text", {
                  attrs: {
                    disabled: _vm.disabled,
                    editable: _vm.editable,
                    label: "LBL0001937",
                    name: "relatedLaws",
                  },
                  model: {
                    value: _vm.moc.relatedLaws,
                    callback: function ($$v) {
                      _vm.$set(_vm.moc, "relatedLaws", $$v)
                    },
                    expression: "moc.relatedLaws",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-checkbox", {
                  attrs: {
                    required: true,
                    disabled: _vm.disabled,
                    editable: _vm.editable,
                    isArray: false,
                    codeGroupCd: "MOC_BENEFIT_CD",
                    itemText: "codeName",
                    itemValue: "code",
                    label: "LBL0001938",
                    name: "mocBenefitCds",
                  },
                  model: {
                    value: _vm.moc.mocBenefitCds,
                    callback: function ($$v) {
                      _vm.$set(_vm.moc, "mocBenefitCds", $$v)
                    },
                    expression: "moc.mocBenefitCds",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-textarea", {
                  attrs: {
                    disabled: _vm.disabled,
                    editable: _vm.editable,
                    label: "LBL0001939",
                    name: "leaderOpinion",
                  },
                  model: {
                    value: _vm.moc.leaderOpinion,
                    callback: function ($$v) {
                      _vm.$set(_vm.moc, "leaderOpinion", $$v)
                    },
                    expression: "moc.leaderOpinion",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("c-upload", {
                  attrs: {
                    attachInfo: _vm.attachInfo,
                    editable: _vm.editable && !_vm.disabled,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }