<template>
  <q-form ref="editForm">
    <!-- 요청상세 -->
    <c-card title="LBL0001922" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <!-- 저장 -->
          <c-btn 
            v-if="editable && !disabled" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="moc"
            mappingType="PUT"
            label="LBLSAVE" 
            icon="save"
            @beforeAction="saveMoc"
            @btnCallback="saveMocCallback" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 변경구분 -->
          <c-select
            :disabled="true"
            :editable="editable"
            codeGroupCd="MOC_TYPE_CD"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="mocTypeCd"
            label="LBL0001906"
            v-model="moc.mocTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- MOC 번호 -->
          <c-text
            :editable="editable"
            :disabled="true"
            label="LBLMOCNO"
            name="mocNo"
            v-model="moc.mocNo">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 등급(작성) -->
          <c-select
            :required="true" 
            :disabled="disabled"
            :editable="editable"
            codeGroupCd="MOC_LEVEL_CD"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="mocWriteLevelCd"
            label="LBL0001929"
            v-model="moc.mocWriteLevelCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 등급(검토) -->
          <c-select
            :editable="editable"
            :disabled="disabled"
            codeGroupCd="MOC_LEVEL_CD"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="mocConfirmLevelCd"
            label="LBL0001930"
            v-model="moc.mocConfirmLevelCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 제목 -->
          <c-text 
            :required="true" 
            :disabled="disabled"
            :editable="editable"
            label="LBLTITLE"
            name="mocTitle"
            v-model="moc.mocTitle">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant 
            :required="true" 
            :disabled="disabled"
            :editable="editable" 
            type="edit" 
            name="plantCd" 
            v-model="moc.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 발의부서/이름 -->
          <c-text
            :editable="editable"
            :disabled="true"
            label="LBL0001923"
            name="initiative"
            v-model="initiative">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 변경 작업 기간 -->
          <c-datepicker
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            :range="true"
            label="LBL0001919"
            name="mocPeriod"
            v-model="moc.mocPeriod"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 변경실행책임자 -->
          <c-field 
            :editable="editable"
            :disabled="disabled"
            :data="moc"
            deptValue="mocActionDeptCd"
            type="dept_user" 
            label="LBL0001931" 
            name="mocActionResponsibilityUserId" 
            v-model="moc.mocActionResponsibilityUserId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 변경완료확인자 -->
          <c-field 
            :editable="editable"
            :disabled="disabled"
            :data="moc"
            deptValue="mocCompleteCheckDeptCd"
            type="dept_user" 
            label="LBL0001932" 
            name="mocCompleteCheckUserId" 
            v-model="moc.mocCompleteCheckUserId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 발의일 -->
          <c-text 
            :editable="editable"
            :disabled="true"
            label="LBL0001905" 
            name="initiativeDt" 
            v-model="moc.initiativeDt" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 관련공정 -->
          <c-process
            :editable="editable"
            :disabled="disabled"
            label="LBL0001705"
            multiple="multiple"
            :required="true"
            name="processCd"
            v-model="moc.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 관련설비 -->
          <c-tag 
            :editable="editable"
            :disabled="disabled"
            :clickable="true"
            itemText="equipmentName"
            itemValue="check"
            label="LBL0001076"
            name="equipments" 
            v-model="moc.equipments"
            @removeTag="removeEquipment"
            @addTag="addEquipment"
            @clickTag="clickTag"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 변경계획에 대한 공정 및 설계의 기술근거 -->
          <c-textarea 
            :disabled="disabled"
            :editable="editable"
            label="LBL0001933"
            name="technicalBasisProcessDesign"
            v-model="moc.technicalBasisProcessDesign">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 변경의 개요와 의견 -->
          <c-textarea 
            :disabled="disabled"
            :editable="editable"
            label="LBL0001934"
            name="mocSummary"
            v-model="moc.mocSummary">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 공정안전 확보를 위한 대책 -->
          <c-textarea 
            :disabled="disabled"
            :editable="editable"
            label="LBL0001935"
            name="processSatefySecureMeasures"
            v-model="moc.processSatefySecureMeasures">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 안전성에 필요한 사항 및 신뢰성 향상 효과 -->
          <c-textarea 
            :disabled="disabled"
            :editable="editable"
            label="LBL0001936"
            name="safetyReliabilityImprovementEffect"
            v-model="moc.safetyReliabilityImprovementEffect">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 관련근거법령 -->
          <c-text 
            :disabled="disabled"
            :editable="editable"
            label="LBL0001937"
            name="relatedLaws"
            v-model="moc.relatedLaws">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 변경 후 기대효과 -->
          <c-checkbox 
            :required="true"
            :disabled="disabled"
            :editable="editable"
            :isArray="false"
            codeGroupCd="MOC_BENEFIT_CD"
            itemText="codeName"
            itemValue="code"
            label="LBL0001938"
            name="mocBenefitCds"
            v-model="moc.mocBenefitCds">
          </c-checkbox>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 팀장의견 -->
          <c-textarea
            :disabled="disabled"
            :editable="editable"
            label="LBL0001939"
            name="leaderOpinion"
            v-model="moc.leaderOpinion">
          </c-textarea>
        </div>
        <div class="col-12">
          <c-upload 
            :attachInfo="attachInfo"
            :editable="editable&&!disabled">
          </c-upload>
        </div>
      </template>
    </c-card>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'moc-request',
  props: {
    moc: {
      type: Object,
      default: () => ({
        sopMocId: '',  // MOC 일련번호
        mocNo: '',  // moc 관리번호
        plantCd: '',  // 사업장 코드
        processCd: '', // 공정 코드
        initiativeUserId: '',  // 발의자 ID
        initiativeUserName: '',  // 발의자 명
        initiativeDeptCd: '',  // 발의 부서 코드
        initiativeDeptName: '',  // 발의 부서명
        initiativeDt: '',  // 발의일
        mocTitle: '',  // 변경관리 제목
        mocSummary: '',  // 변경 개요
        mocTypeCd: 'MT00000001',  // 변경구분_공통코드(정상/비상/임시)
        mocWriteLevelCd: null,  // 변경등급_작성
        mocConfirmLevelCd: null,  // 변경등급_검토
        processSatefySecureMeasures: '',  // 공정안전 확보 대책
        technicalBasisProcessDesign: '',  // 변경계획에 대한 공정 및 설계의 기술근거
        safetyReliabilityImprovementEffect: '',  // 안전성에 필요한 사항 및 신뢰성 향상 효과
        leaderOpinion: '',  // 팀장의견
        relatedLaws: '',  // 관련 볍령_텍스트
        processRiskAssessFlag: '',  // 공정위험성 평가 여부
        jobRiskAssessFlag: '',  // 작업위헝성 평가 여부
        mocPeriod: [],
        mocStartDate: '',  // 변경 시작일
        mocEndDate: '',  // 변경 종료일
        mocStepCd: '',  // 변경관리 단계
        mocActionDeptCd: '',  // 변경실행 부서코드
        mocActionResponsibilityUserId: '',  // 변경실행 책임자 ID
        mocCompleteCheckUserId: '',  // 변경완료 확인자 ID
        mocCompleteCheckDeptCd: '',  // 변경완료 확인 부서 코드
        mocCompleteOpinion: '',  // 변경완료 의견
        mocBenefitCds: '',  // 변경관리 기대효과_복수
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        equipments: [],
        deleteEquipments: [],
      }),
    },
    disabled: {
      type: Boolean,
      default: function() {
        return false;
      },
    },
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
      attachInfo: {
        isSubmit: '', // 판정에서 데이터가 저장되어 키가 있는 상황임으로 사용하지 않음
        taskClassCd: 'MOC_REQUEST',
        taskKey: '',
      },
      editable: true,
      saveUrl: '',
      isSave: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    initiative() {
      return this.moc.initiativeDeptName + ' / ' + this.moc.initiativeUserName
    },
  },
  watch: {
    'moc.sopMocId'() {
      this.setTaskKey();
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.sop.moc.change.update.url
      // code setting
      // list setting
      this.setTaskKey();
    },
    setTaskKey() {
      let taskKey = this.moc && this.moc.sopMocId ? this.moc.sopMocId : '';
      this.$set(this.attachInfo, 'taskKey', taskKey)
    },
    removeEquipment(item) {
      if (!this.moc.deleteEquipments) {
        this.moc.deleteEquipments = [];
      }
      if (this.$_.findIndex(this.moc.equipments, { equipmentCd: item.equipmentCd }) > -1
        && item.editFlag !== 'C') {
        this.moc.deleteEquipments.push(item);
      }
      this.moc.equipments = this.$_.reject(this.moc.equipments, item)
    },
    addEquipment() {
      this.popupOptions.title = 'LBL0000521'; // 설비 검색
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/equip/equipPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeFacilityPopup;
    },
    closeFacilityPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.moc.equipments, { equipmentCd: item.equipmentCd }) === -1) {
            this.moc.equipments.push({
              equipmentCd: item.equipmentCd,
              equipmentName: item.equipmentName,
              restorationFlag: 'N',
              processingHistory: '',
              regUserId: this.$store.getters.user.userId,
              editFlag: 'C',
            })
          }
        })
      }
    },
    clickTag(tag) {
      this.popupOptions.title = 'LBL0000955'; // 설비 상세
      this.popupOptions.param = tag;
      this.popupOptions.target = () => import(`${'@/pages/mdm/fim/equipmentDetail.vue'}`);
      this.popupOptions.width = '90%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    saveMoc() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.moc.chgUserId = this.$store.getters.user.userId
              this.moc.mocStartDate = this.moc.mocPeriod[0]
              this.moc.mocEndDate = this.moc.mocPeriod[1]

              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveMocCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('saveCallback', _result.data)
    },
  }
};
</script>
